import React, { Component } from 'react'
import Title from './Title'
import Photowall from './PhotoWall'
import PostNumber from './PostNumber'
import AddPhoto from './AddPhoto'
import { Route } from 'react-router-dom'

class Main extends Component {
    constructor() {
        super()
        this.state = {
            posts: [],
            screen: 'photos' // photos, addPhoto
        }

        this.removePhoto = this.removePhoto.bind(this)
        this.addPhoto = this.addPhoto.bind(this)
    }

    removePhoto(postRemoved) {
        this.setState((state) => ({
            posts: state.posts.filter(post => post !== postRemoved)
        }))
    }

    addPhoto(postSubmited) {
        this.setState((state) => ({
            posts: state.posts.concat([postSubmited])
        }))

    }

    componentDidMount() {
        const data = fetchInfo()
        this.setState({
            posts: data

        })
    }

    render() {
        return <div>
            <Route exact path="/" render={() => (
                <div>
                    <Title title={'Photowall'} />
                    <PostNumber number={this.state.posts.length} />
                    <Photowall posts={this.state.posts} onRemovePhoto={this.removePhoto} />
                </div>
            )} />
            {/* <Route exact path="/AddPhoto" component={AddPhoto} /> */}

            <Route exact path="/AddPhoto" render={({ history }) => (
                // <AddPhoto onAddPhoto={(newPost)=>{
                //     this.addPhoto(newPost)
                //     history.push('/')
                // }} /> 

                <AddPhoto onAddPhoto={this.addPhoto} history={history} />

            )} />

        </div>

    }
}

function fetchInfo() {
    return [{
        id: 0,
        desc: "imagen 0",
        imageLink: "https://concepto.de/wp-content/uploads/2015/03/paisaje-e1549600034372.jpg"
    }, {
        id: 1,
        desc: "imagen 1",
        imageLink: "https://concepto.de/wp-content/uploads/2015/03/paisaje-e1549600034372.jpg"
    }, {
        id: 2,
        desc: "imagen 2",
        imageLink: "https://concepto.de/wp-content/uploads/2015/03/paisaje-e1549600034372.jpg"
    }]
}

export default Main