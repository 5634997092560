import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import Main from './Components/Main'
import './styles/stylesheet.css'
import {BrowserRouter} from 'react-router-dom'
ReactDOM.render(<BrowserRouter><Main /></BrowserRouter>, document.getElementById('root'));
// const tasks = ['saca la basura', 'pasea al perrosss', 'haz la tareas']
// const element =
//     <div>
//         <h1>Task List</h1>
//         <ol>
//             {tasks.map((task, index) => <li key={index}> {task} </li>)}
//         </ol>
//     </div>

// React.createElement('ol', null,
//     tasks.map((task, index) =>  React.createElement('li', { key: index }, task))
// );