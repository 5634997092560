import React from 'react'
import PropTypes from 'prop-types'

function Photo(props) {
    const post = props.post
    return <figure className="figures">
        <img className="photo" src={post.imageLink} alt={post.desc} />
        <figcaption><p> {post.desc}</p></figcaption>
        <div className="buttoncontainer">
            <button className="remove-button" onClick= {() => {
                props.onRemovePhoto(post)
            }}>Remove</button>
        </div>
    </figure>
}

Photo.propTypes = {
    post: PropTypes.object.isRequired,
    onRemovePhoto: PropTypes.func.isRequired
}

export default Photo